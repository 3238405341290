.development_courses_page{
    background-color: var(--main_color);
    min-height: 100vh;
}


.development_courses_header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;
}

.development_courses_header_left{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.development_courses_header_left h1{
    width: 412px;
    font-size: 40px;
    font-weight: 900 !important;
    color: var(--main_font_color);
}

.development_courses_header_left p{
    width: 412px;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 26.5px;
    color: var(--main_font_color);
}

.development_courses_header_left ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-left: 0;
}

.development_courses_header_left button{
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 400!important;
    border: none;
    background-color: #5D64FF;
    color: white;
}

.development_courses_header_left button:hover{
    background-color: #424AFF;
}

.development_courses_list li{
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: var(--main_font_color);
    font-size: 16px;
    font-weight: 700 !important;
}

.development_courses_header_right{
    background-image: url('../../images//Illustration.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    height: 100%;
    width: 55%;
    height: 550px;
}


/* ==================== */


.development_courses_certificate{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.development_courses_certificate_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.development_courses_certificate_title h2{
    text-align: center;
    font-weight: 900 !important;
    color: var(--main_font_color);
    max-width: 550px;
}

.development_courses_certificate_title p{
    text-align: center;
    font-weight: 400 !important;
    color: var(--main_font_color);
    max-width: 550px;
}


.development_courses_certificate_block{
    background-color: #111635;
    padding: 100px 0;
}


.development_courses_certificate_block .container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.development_courses_certificate_block_left, .development_courses_certificate_block_right{
    width: 329px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.development_courses_certificate_block_left >img, .development_courses_certificate_block_right >img{
    width: 100%;
    height: 232px;
}

.development_courses_certificate_block_left h3, .development_courses_certificate_block_right h3{
    text-align: center;
    font-weight: 700 !important;
    color: var(--main_font_color);
}


@media(max-width: 1024px){
    .development_courses_header{
        flex-direction: column;
        row-gap: 50px;
    }

    .development_courses_header_right{
        width: 90%;
        background-position: left;
    }

    .development_courses_header_left{
        width: 90%;
    }

    .development_courses_certificate_block .container{
        flex-direction: column;
    }

    .development_courses_certificate_block_left, .development_courses_certificate_block_right{
        width: 460px;
    }

    .development_courses_certificate_block_left >img, .development_courses_certificate_block_right >img{
        height: 332px;
    }
}


@media(max-width: 480px){
    .development_courses_header_left h1{
        width: 100%;
        font-size: 30px;
    }

    .development_courses_header_left p{    
        width: 100%;
        font-size: 14px;
    }

    .development_courses_header_right{
        height: 300px;
    }

    .development_courses_certificate_block_left, .development_courses_certificate_block_right{
        width: 329px;
    }

    .development_courses_certificate_block_left >img, .development_courses_certificate_block_right >img{
        height: 232px;
    }
}