
.slide-section{
    padding: 100px 0;
}

.slide-section h2, .slide-section p{
    color: var(--main_font_color);
    text-align: center;
}
.react-multiple-carousel__arrow{
    display: none;
}

.react-multi-carousel-list{
    position: static;
}


.slide-section-items{
    position: relative;
    padding: 100px 0;
    padding-top: 70px;
}


.react-multi-carousel-dot-list{
    bottom: -30px;
}



.react-multi-carousel-item{
    transform-style: flat !important;
}

.react-multi-carousel-track{
    transform-style: flat !important;

}


@media(min-width: 1140px){
    .react-multi-carousel-item{
        width: 408px !important;
        
    }
}