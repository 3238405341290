.create-website-mobile-app{
    display: flex;
    flex-direction: column;
    row-gap: 150px;
    align-items: center;
    padding: 100px 0;
}


.create-website-mobile-app-items{
    max-width: 1152px;
    background-color: var(--main_color);
    border-radius: 26px;
    padding: 20px;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    overflow: hidden;

}

.create-website-mobile-app-block{
    display: flex;
    gap: 50px;
}

.create-website-mobile-app >h2{
    font-size: 25px;
    width: 70%;
}

.slide-btns{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slide-btns button{
   width: 30px;
   height: 30px;
   background: transparent;
   outline: none;
   border: none;
   color: var(--main_font_color);
   font-size: 20px;
}

.slide-btns button:focus{
    border-bottom: 1px solid var(--main_font_color);
}

@media(max-width: 480px){
    .create-website-mobile-app-items{
        padding: 50px 20px;
    }
}