/* .accordion-button:not(.collapsed) {
  background-color: red;
} */
.AllHelp{
  width: 95%;
}
.help-title{
  font-size: 24px;
  font-weight: 400;
  line-height: 29.3px;
}
.fast-support{
  background-color: #FFFFFF;
  color: #525C76;
  padding: 20px;
  font-size: 24px;
  margin: 0;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}
.often-asked-question{
  background-color: #FFFFFF;
  margin: 0;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.write-your-question{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 30px;
  padding-top: 25px;
  width: 45vw;
}
.write-your-question-input{
  width: 100%;
  border: 1px solid #9ca3af;
  border-radius: 10px;
  padding: 8px;
  min-width: 220px;
}
.write-your-question-textarea{
  border: 1px solid #9ca3af;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
}
.write-your-question-title{
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  color: #525C76;
}
.succesMessge{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.succesMessge span{
  color: #00794E;
}
@media screen and (max-width: 965px) {
  .write-your-question {
    align-items: center;
    width: 100%;
  }
  .write-your-question-input{
    width: 60%;
  }
  .write-your-question-textarea{
    width: 60%;
  }
}