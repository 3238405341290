 a{
    color: var(--main_font_color);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media(min-width: 1920px){
     a{
        font-size: 20px;
    }
  }


@media(max-width: 1920px){
     a{
        font-size: 17px;
    }
  }

@media(max-width: 1440px){
     a{
        font-size: 15px;
    }
  }


  @media(max-width: 1140px){
     a{
        font-size: 13px;
    }
  }