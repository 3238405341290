.createItemWebsitesAndMobileApp {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-height: 330px;
    animation: slideAnim 2s ;
}

.createItemWebsitesAndMobileApp h4{
    color: var(--main_font_color);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 350px;
    padding: 7px;
    /* border-radius: 10px; */
    border-bottom: 1px solid #E4E4E4;
}


.createItemWebsitesAndMobileApp p{
    color: var(--main_font_color);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    transition: all .6s;
    transform-origin: top center;
    width: 80%;
}



@media(max-width: 1024px){
    .createItemWebsitesAndMobileApp p{
        font-size: 16px;
    }
}



@media(max-width: 480px){
    .createItemWebsitesAndMobileApp h4{
        font-size: 20px;
    }

    .createItemWebsitesAndMobileApp p{
        font-size: 14px;
    }
}



@keyframes slideAnim{
    to{
        transform: translateX(0);
    }
    from{
        transform: translateX(100%);
    }
}