.tel-us{
    /* background-image: url('../../images/postive-caring-relationships-teachers 2.png');
    background-position: center center;
    background-size: cover; */
    padding-bottom: 50px;
}


div.tel-us .container{
    padding: 0;
}

.tel-us-block{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap-reverse;
   gap: 100px;
   background: #AEAEAE1A;
   border: 1px solid #5D64FF;
   /* backdrop-filter: blur(336px); */
   width: 100%;
   padding: 50px;
   border-radius: 20px;
   min-height: 950px;
}

 .tel-us-form-div{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: flex-start;
    /* height: 100%; */
}





.tel-us-form-div  form{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: flex-start;
    width: 552px;
    height: 100%;
}

.tel-us-form-div  form div{
    width: 100%;
}
.tel-us-form-div h4{
    color: var(--main_font_color);
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* width: 850px; */
}

.tel-us-form-inps{
    display: flex;
    gap: 20px;
}

.tel-us-form-inps >div{
    width: 264px;
}

h4.input_tile{
    color: var(--main_font_color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    margin: 0;
    text-align: left;
    padding-bottom: 15px;
}


.tel-us-form-div  form input {
    width: 100%;
    height: 40px;
    border: 1px solid #5D64FF;
    background-color: transparent;
    padding: 0 10px;
    outline: none;
    color: var(--main_font_color);
    border-radius: 4px;
}

.tel-us-form-div  form textarea {
    min-width: 100%;
    max-width: 100%;
    height: 224px;
    border: 1px solid #5D64FF;
    background-color: transparent;
    padding: 0 10px;
    outline: none;
    color: var(--main_font_color);
    border-radius: 4px;

}


.tel-us-form-div form .error{
    color: red;
    font-size: 12px;
    padding-top: 10px;
    margin: 0;
}


.tel-us-form-div .upload-file-div{
    position: relative;
}

.tel-us-form-div .upload-file-div .fileIcon{
    position: absolute;
    right: 2%;
    top: 47px;
    color: gray;
}


.tel-us .container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 180px;
}

.tel-us .container .tel-us-title h3{
    color: var(--main_font_color);
    font-size: 28px;
    font-style: normal;
    font-weight: 800!important;
}

.tel-us-title p{
    max-width: 315px;
    color: var(--main_font_color);
    font-size: 14px;
}



.tel_us_btn{
    background-color: #5D64FF;
    border: none;
    outline: none;
    color: white;
    font-weight: 700 !important;
    padding: 7px 30px;
    border-radius: 8px;
}

.tel_us_btn:hover{
    background-color: #424AFF;
}


/* ====================== */


.tel-us-right-div{
    background-color: #5D64FF;
    height: 820px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 30px 50px;
    border-radius: 20px;
    width: 40%;
}

.tel-us-right-div >div{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tel-us-right-div-social-media-div{
    display: flex;
    gap: 10px;
}

.tel-us-right-div >h3{
    color: white;
    font-size: 28px;
    font-weight: 800 !important;
    max-width: 200px;
}

.tel-us-right-div-social-media >span{
    font-size: 20px;
    font-weight: 800 !important;
    color: white;
}

@media(max-width: 1440px){
    .tel-us .container{
        padding: 50px 100px;
    }
}

@media(max-width: 1140px){
    .tel-us-right-div{
        height: 700px;
        width: 90%;
    }

}

@media(max-width: 1024px){
    .tel-us .container{
        padding: 100px;
        flex-direction: column;
    }

    .tel-us .container .tel-us-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 383px;
    }
}


@media(max-width: 780px){
    
    .tel-us .container .tel-us-title h3{
        font-size: 78px;
    }

    .tel-us .container .tel-us-title h5{
        font-size: 34px;
    }

    .tel-us-form-div  form{
        width: 383px;
    }

    .tel-us-form-inps{
        flex-direction: column;
    }

    .tel-us-right-div >h3{
        font-size: 24px;
    }
}



@media(max-width: 480px){
    .tel-us .container .tel-us-title h3{
        font-size: 58px;
    }

    .tel-us .container .tel-us-title h5{
        font-size: 24px;
    }

    .tel-us .container .tel-us-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 300px;
    }

    .tel-us-form-div  form{
        width: 300px;
    }

    .tel-us-right-div >h3{
        font-size: 20px;
    }
}

