#button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 120px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
 
  #button:hover >svg {
    cursor: pointer;
    color: #270bc4;
  }
  #button:active {
    background-color: #270bc4;
  }
 
  
  /* Styles for the content section */
  @media (min-width: 500px) {
    #button {
      margin: 30px;
    }
  }
