.section-title{
    color: var(--main_font_color);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}


@media(max-width: 1024px){
    .section-title{
        font-size: 30px;
    }
}


