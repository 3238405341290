.footer{
    background-color: var(--main_color);
    background-position: center;
    max-width: 100%;
}

.footer-content{
    background-color: #020838;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgb(26, 26, 121);
    border-top-right-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 60px 0;
}

.footer-menu{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footer-right-div{
    height: 100%;
    display: flex;
    align-items: flex-end;
    column-gap: 20px;
}

.footer-menu li span{
    color: var(--main_font_color);
}

.footer-menu li{
    color: gray;
}

.footer-menu li a{
    color: gray;
}

.footer-right-div span{
    color: var(--main_font_color);
}

.footer-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}



@media(max-width: 1024px){
    .footer-menu li{
        font-size: 13px;
    }

    .footer-right-div span{
        font-size: 16px;
    }

    .footer-right-div .footer-icons a svg{
        width: 13px;
        height: 13px;
    }
}


@media(max-width: 720px){
    .footer-content{
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .footer-right-div span{
        font-size: 10px;
    }
    
}


@media(max-width: 480px){
    .footer-right-div {
        margin-bottom: 50px;
    }
    .footer-right-div{
        flex-direction: column;
        gap: 10px;
        width: 60%;
        align-items: flex-start;
    }

    .footer-menu{
        width: 60%;
    }
   

}