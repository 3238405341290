.share_div {
   position: relative;
   cursor: pointer;
   /* filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
      brightness(112%) contrast(99%);
   filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
      brightness(112%) contrast(99%); */
   /* color: white; */
   /* background-color: white; */
}

.share-buttons {
   width: 120px;
   flex-direction: column;
   align-items: flex-start;
   gap: 10px;
   background-color: white;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.704);
   border-radius: 6px;
   padding: 10px;
   position: absolute;
   top: 25px;
   left: 0;
   display: none;
   z-index: 999999;
}

.share-buttons-text{
   color: black !important;
}
.share_div:hover .share-buttons {
   display: flex;
   color: black;
}

/* .share_div:hover  span svg path {
   fill: black;
} */


.share-buttons span:hover {
   color: black;
}
.share-buttons button{
   color: black;
}
