.news_main_page_categorys .container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 0 20px; /* Adjust as needed */
   gap: 50px;
}

.news_main_page_categorys_div {
   width: 100%;
   max-width: 950px;
   display: flex;
   flex-direction: column;
   gap: 30px;
   justify-content: center;
   align-items: flex-start;
}

.news_main_page_categorys_div h3 {
   cursor: pointer;
   color: var(--main_font_color)
}

.news_main_page_categorys_div_content_item p{
   color: var(--main_font_color)
}
.news_main_page_categorys_div_content {
   width:100%;
   max-width: 100%;
   display: flex;
   flex-wrap: wrap; /* Items will wrap to the next line if they don't fit */
   gap: 20px;
}

.news_main_page_categorys_div_content_item {
   flex-grow: 1;
   flex-basis: calc(33.33% - 20px); 
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   gap: 10px;
   cursor: pointer;
   width: 300px;
}

.news_main_page_categorys_div_content_item_img_div {
   width: 100%;
   height: 300px;
   overflow: hidden;
}

.news_main_page_categorys_div_content img {
   width: 100%;
   height: 500px;
   border-radius: 0;
   transition: all 1s;
}

.news_main_page_categorys_div_content img:hover {
   transform: scale(1.2);
}

.news_main_page_categorys_div_content p {
   font-size: 14px;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   position: relative;
}

.news_main_page_categorys_div_content p::after {
   content: '...';
   position: absolute;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 1.5em;
}

.news_main_page_categorys_div_content_item_share_and_date_div >span {
   color: white;
}

.news_main_page_categorys_div_content_item_share_and_date_div {
   display: flex;
   align-items: center;
   gap: 20px;
}

.news_main_page_categorys_div_content_item_share_and_date_div > span {
   border-right: 1px solid white;
   padding-right: 20px;
}

.news_main_page_categorys_div h3 {
   display: flex;
   align-items: center;
   gap: 20px;
}
.news_main_page_categorys_div h3 svg {
   filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
      brightness(112%) contrast(99%);
   color: white;
   width: 770px;
}

@media (max-width: 1024px) {
   .news_main_page_categorys_div h3 svg {
      width: 75%;
      filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
         brightness(112%) contrast(99%);
      color: white;
   }
}

@media (max-width: 780px) {
   .news_main_page_categorys_div h3 {
      flex-direction: column;
      align-items: flex-start;
   }

   .news_main_page_categorys_div h3 svg {
      width: 100%;
      filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
         brightness(112%) contrast(99%);
      color: white;
   }
}
