

.order-modal{
    display: flex;
    justify-content: center;
    align-items: center;                                                         
    position: fixed;                                                             
    z-index: 99999999999;                         
    left: 0;                                                                                           
    top: 0;                                                                                            
    width: 100%; /* Full width */                                                                      
    height: 100%; /* Full height */                                                                    
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.64);
    padding: 100px 0;
}


.order-modal-block{
    margin-top: 600px;
    width: 40%;
    background-color: #010526;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    animation: modalOrderAnim .5s;
    overflow-y: auto;
}

.order-modal-block-info-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    text-align: center;
}

.order-modal-block-info-div h3{
    color: #FFF;
    font-family: 'TitleFont';
    font-size: 44px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    text-align: center;
}

.order-modal-block-info-div span{
    color: #6644BC;
    font-family: 'TitleFont';
    font-size: 34px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    /* width: 381px;;+ */
}


.order-modal-form .order-modal-full-div{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
}

.order-modal-form .order-modal-full-div::-webkit-scrollbar {
    width: 0;
}

.order-modal-form .order-modal-full-div > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.order-modal-form .order-modal-full-div > div p{
    color: red;
    font-size: 13px;
}
.order-modal-form{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    width: 100%;
}

.order-modal-form-div form div{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.order-modal-form input{
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid white;
    background-color: transparent;
    padding: 0 10px;
    outline: none;
    color: var(--main_font_color);
}


.order-modal-block textarea{
    min-width: 100%;
    max-width: 100%;
    min-height: 40px;
    height: 40px;
    border: none;
    border-bottom: 1px solid white;
    background-color: transparent;
    padding: 0 10px;
    outline: none;
    color: var(--main_font_color);
}

.upload-file-div{
    position: relative;
}

.upload-file-div .fileIcon{
    position: absolute;
    right: 3%;
    top: 0;
    color: gray;
}


.upload-file-div .fileIcon:hover svg path{
    stroke: var(--main_font_color);
}

.order-modal-block div.checkbox-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.checkbox-div > span{
    color: var(--main_font_color);
    font-family: 'TitleFont';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.order-modal-block .checkobox-items{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}


.order-modal-block .checkobox-items div{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.checkobox-items div input{
    width: 20px;
    height: 20px;
}

.checkobox-items input[type=checkbox] + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
    font-family: 'Arial';
    font-size: 13px;
  }
  
 .checkobox-items input[type=checkbox] {
    display: none;
  }
  
  .checkobox-items input[type=checkbox] + label:before {
    content: "\2714";
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 1em;
    height: 1em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.8em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    border-color: var(--main_font_color);
    box-sizing: content-box
  }
  
 .checkobox-items input[type=checkbox] + label:active:before {
    transform: scale(0);
  }
  
  input[type=checkbox]:checked + label:before {
    background-color: #6644BC;
    border-color: #6644BC;
    color: #fff;
  }
  
  input[type=checkbox]:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }
  
  input[type=checkbox]:checked:disabled + label:before {
    transform: scale(1);
    background-color: #6644BC;
    border-color:#6644BC;
  }

.checkobox-items div label{
    color: var(--main_font_color);
}

.checkbox-div span{
    color: var(--main_font_color);
}

.order-modal-form .btn{
    width: 150px;
    font-size: 14px;
}

.error_captcha{
    color: red;
}



 @media(max-width: 1440px){
    .order-modal-block{
        width: 75%;
        margin-top: 600px;
    }
    
} 


@media(max-width: 1140px){
    .order-modal-block{
        width: 85%;
    }
    
}

@media(max-width: 1024px){
    

    .order-modal-block{
        width: 70%;
        height: 570px;
        margin-top: 100px;
        overflow: hidden;
    }

    .checkobox-items div{
        width: 75%;
    }

    .order-modal-block{
        padding: 20px;
    }

    .submit-div {
        width: 60%;
        display: flex;
        justify-content: center;
    }


    .order-modal-form .order-modal-full-div{
        height: 450px;
        overflow-y: auto;
    }
    
} 

 @media(max-width: 720px){
    .order-modal-block{
        height: 500px;
    }

    .order-modal-block{
        width: 80%;
        height: 470px;
    }

    .submit-div {
       width: 80%;
       /* top: 470px; */
    }

    .order-modal-form .order-modal-full-div{
        height: 350px;
        overflow-y: auto;
    }

}

@media(max-width: 480px){
    .order-modal-block textarea{
        height: 20px;
    }

    .order-modal-block span{
        font-size: 16px;
    }

    .order-modal-block{
        width: 90%;
    }

    .submit-div {
        width: 80%;
        /* top: 470px; */
     }
}  


@keyframes modalOrderAnim {
    to{
        transform: scale(1);
    }
    from{
        transform: scale(0);
    }
}


