.turnstile_page_item{
    width: 322px;
    background-color: #2D3189;
    border-radius: 20px;
    padding: 10px;
}



.turnstile_page_item_img_div{
    width: 100%;
    height: 268px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-size: cover;
}

.turnstile_page_item_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
}

.turnstile_page_item_info h3{
    color: white;
    font-size: 20px;
}

.turnstile_page_item_info_icons{
    display: flex;
    gap: 30px;
}

.turnstile_page_item_info_icons >div{
    display: flex;
    gap: 10px;
}

.turnstile_page_item_info_icons >div:nth-of-type(1){
   
    padding-right: 30px;
}

.turnstile_page_item_info_icons >div span{
    font-weight: 700;
    font-size: 20px;
    color: white;

}

.turnstile_page_item a{
   font-size: 16px ;
   padding: 7px 20px;
}

