.OurAdvantagesItem{
    width: 273px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 0px 26px 26px 0px;
    background: rgba(56, 60, 162, 0.80);
    box-shadow: 2px 2px 4px 0px #272A7B;
    position: relative;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}



/* ==================================================================== */
.OurAdvantagesItem:nth-of-type(1), .OurAdvantagesItem:nth-of-type(4), .OurAdvantagesItem:nth-of-type(5), .OurAdvantagesItem:nth-of-type(8){
    transition: all 1s;
}

.OurAdvantagesItem:nth-of-type(2), .OurAdvantagesItem:nth-of-type(3), .OurAdvantagesItem:nth-of-type(6), .OurAdvantagesItem:nth-of-type(7), .OurAdvantagesItem:nth-of-type(9), .OurAdvantagesItem:nth-of-type(10), .OurAdvantagesItem:nth-of-type(11) {
    transition: all .7s;
}


.OurAdvantagesItem h4{
    color: var(--main_font_color);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* .OurAdvantagesItem p{
    color: rgba(255, 255, 255, 0.95);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow-y: auto;
    padding-bottom: 20px;
    cursor: all-scroll;
}

.OurAdvantagesItem p::-webkit-scrollbar {
    width: 0;
  } */


  .OurAdvantagesItem p {
    color: rgba(255, 255, 255, 0.95);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow-y: auto;
    padding: 0 2px;
    padding-bottom: 20px;
    max-height: 200px; 
}

/* Սքրոլի ոճի համար */
.OurAdvantagesItem p::-webkit-scrollbar {
    width: 5px;
}

.OurAdvantagesItem p::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5); /* Սքրոլի thumb գույն */
    border-radius: 10px; /* Կլորացված սքրոլ */
}

.OurAdvantagesItem >div{
    /* width: 273px; */
    height: 26px;
    flex-shrink: 0;
    border-radius: 26px 0px;
    background: #4E3584;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}