.containerProfilePage{
    /* max-width: 1140px; */
    margin: 0 auto;
    /* padding: 0 29px; */
    box-sizing: border-box;
  
  background-color: #1A1A42;
  /* #010526 */
  /* rgb(45, 101, 80); */
  
}


.ProfilePage_bigDiv{
  display: flex;
  /* padding-top: 51px; */
}