.web_promotion {
   min-height: 100vh;
   background-color: var(--main_color);
}

.web_promotion .container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   /* align-items: center; */
   overflow: hidden;
   padding: 50px 0;
}

.web_promotion_top_div{
   width: 100%;
   background-color: #AEAEAE1A;
   /* backdrop-filter: blur(336px); */
   border: 1px solid #5D64FF;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px;
   gap: 50px;
   border-radius: 20px;
}

.web_promotion_top_div_title{
   color: var(--main_font_color);
   font-weight: 700;
   font-size: 32px;
   text-align: center;
}

.web_promotion_top_div_img_div{
   width: 70%;
   height: 571px;
}

.web_promotion_top_div_img_div img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}





.web_promotion_top_div_info{
   width: 70%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}



.web_promotion_top_div_info >p{
   color: var(--main_font_color);
   font-weight: 500;
}


.web_promotion_top_div_info_div1{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


.web_promotion_top_div_info_div2{
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.web_promotion_top_div_info_div1 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}


.web_promotion_top_div_info_div1 p{
   color: var(--main_font_color);
   font-weight: 500;
}


.web_promotion_top_div_info_div2 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}

.web_promotion_top_div_info_div2 ol{
   display: flex;
   flex-direction: column;
   gap: 15px;
}


.web_promotion_top_div_info_div2 ol li{
   color: var(--main_font_color);
   font-weight: 500;
}

.web_promotion_top_div_info_div3{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.web_promotion_top_div_info_div3 >p{
   color: var(--main_font_color);
   font-weight: 500;
   font-size: 16px;
   text-align: center;
}

.web_promotion_top_div_info_div3_items{
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 50px;
}


.web_promotion_top_div_info_div3_item{
   width: 45%;
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.web_promotion_top_div_info_div3_item >h3{
   color: var(--main_font_color);
   font-weight: 900 !important;
   font-size: 24px;
}


.web_promotion_top_div_info_div3_item p{
   color: var(--main_font_color);
   font-weight: 500;
}


.web_promotion_our_partners .container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 50px;
}

.web_promotion_our_partners_titles_div {
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
}


.web_promotion_our_partners_titles_div h2{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 28px;
   text-align: center;
}


.web_promotion_our_partners_titles_div p{
   color: var(--main_font_color);
   font-weight: 500;
   text-align: center;
}



@media(max-width: 1024px){
   .web_promotion_top_div_img_div{
     width: 90%;
     height: 471px;
   }
  
   .web_promotion_top_div_info{
     width: 90%;
   }
  }
  
  
  
  @media(max-width: 768px){
     .web_promotion_top_div_img_div{
       width: 100%;
       height: 371px;
     }
    
     .web_promotion_top_div_info{
       width: 100%;
     }
  
     .web_promotion_top_div{
        padding: 50px 20px;
     }

     .web_promotion_top_div_info_div3_item{
       width: 100%;
     }
    }
  
  
    @media(max-width: 480px){
     .web_promotion_our_partners_titles_div p{
        width: 300px;
     }
  }