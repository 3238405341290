.mobail_app_developend_page{
   background-color: var(--main_color);
   min-height: 100vh;
}

.mobail_app_developend_page .container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   /* align-items: center; */
   overflow: hidden;
   padding: 50px 0;
}

.mobail_app_developend_page_top_div{
   width: 100%;
   background-color: #AEAEAE1A;
   /* backdrop-filter: blur(336px); */
   border: 1px solid #5D64FF;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px;
   gap: 50px;
   border-radius: 20px;
}

.mobail_app_developend_page_top_div_title{
   color: var(--main_font_color);
   font-weight: 700;
   font-size: 32px;
   text-align: center;
}

.mobail_app_developend_page_top_div_img_div{
   width: 70%;
   height: 571px;
}

.mobail_app_developend_page_top_div_img_div img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}


.mobail_app_developend_page_top_div_info{
   width: 70%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}



.mobail_app_developend_page_top_div_info >p{
   color: var(--main_font_color);
   font-weight: 500;
}


.mobail_app_developend_page_top_div_info_div1{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


.mobail_app_developend_page_top_div_info_div2{
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.mobail_app_developend_page_top_div_info_div1 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}


.mobail_app_developend_page_top_div_info_div1 p{
   color: var(--main_font_color);
   font-weight: 500;
}


.mobail_app_developend_page_top_div_info_div2 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}

.mobail_app_developend_page_top_div_info_div2 ol{
   display: flex;
   flex-direction: column;
   gap: 15px;
}


.mobail_app_developend_page_top_div_info_div2 ol li{
   color: var(--main_font_color);
   font-weight: 500;
}




@media(max-width: 1024px){
 .mobail_app_developend_page_top_div_img_div{
   width: 90%;
   height: 471px;
 }

 .mobail_app_developend_page_top_div_info{
   width: 90%;
 }
}



@media(max-width: 768px){
   .mobail_app_developend_page_top_div_img_div{
     width: 100%;
     height: 371px;
   }
  
   .mobail_app_developend_page_top_div_info{
     width: 100%;
   }

   .mobail_app_developend_page_top_div{
      padding: 50px 20px;
   }
  }