.turnstile_section_home{
    padding-top: 100px;
}

.turnstile_section_home_items_block{
    background-color: #0E082F;
    padding: 30px;
    border-radius: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}


.turnstile_section_home_items{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    
}


.turnstile_section_home_title{
    text-align: center;
    color: white;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    padding-bottom: 30px;
}


/* .turnstile_section_home_btn{
    padding: 5px 15px;
    background-color: #5B62FF;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
} */

