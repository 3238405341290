.our-advantages{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
}

.OurAdvantagesItem h4{
    color: var(--main_font_color);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.our-advantages-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    overflow: hidden;
}

