/* .allLIne {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  padding-top: 40px;
}

.lineLeft ,
.lineRight
{
  position: relative;
  padding-bottom: 10px;
}
.lineLeft::after,
.lineRight::after 
 {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #ffffff;
  width: 100%;
}
.lineLeftText {
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
}
.addition{
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}

.lineRight {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blockImg {
  width: 26px;
  height: 26px;
} */

/* .lineRight {
  position: relative;
  padding-bottom: 20px;
} */
/* .lineRight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #ffffff;
  width: 100%;
} */
/* .lineRightTime {
  color: #ffffff;
  font-size: 20px;
} */

.homeworkes_linne_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5F5F60;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 8px;
  margin-bottom: 18px;
  gap: 10px;
}

.homeworkes_text_dark{
  font-size: 17px;
  line-height: 24.41px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);;
}
.homeworkes_text_dark{
  font-size: 17px;
  line-height: 24.41px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.2);;
}

.homeworkes_duration{
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 850px) {
  .homeworkes_text{
    font-size: 14px;
  }
  .homeworkes_text_dark{
    font-size: 14px;
  }
  .homeworkes_linne_div{
    gap: 9px;
  }
  .homeworkes_duration{
    font-size: 14px;
  }
}
