.aboute-us-page{
    background-color: var(--main_color);
}

.aboute-us-page .container {
    display: flex;
    justify-content: center;
    flex-direction: column; 
    align-items: center;
    overflow: hidden;
}

.aboute-us-page  .aboute-us-top-div{
    height: 300px;
    background-image: url('../../images/postive-caring-relationships-teachers\ 1\ \(1\).png');
    background-position: center center;
    background-size: cover;
}

.aboute-us-page  .aboute-us-top-div .container{
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-bottom: 50px;
}

.aboute-us-page h2{
    color: var(--main_font_color);
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "TitleFont";
    text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #010526 29%,
      #7C1DC9 67%,
      white 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
        font-size: 190px;
}


.aboute-us-page  .aboute-us-top-div .container p{
    color: var(--main_font_color);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    white-space: pre-line;
}

/* ======================================== */
.aboute-us-info-block{
    padding: 50px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    
}

.aboute-us-info-block-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.aboute-us-page h4{
    color: var(--main_font_color);
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 850px;
}

.aboute-us-info-block p{
    color: var(--main_font_color);
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; 
    white-space: pre-line;
}

.aboute-us-info-block-training {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.aboute-us-page h5{
    color: var(--main_font_color);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}

.aboute-us-info-block-training div{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.aboute-us-info-block-training p{
    color: var(--main_font_color);
    white-space: pre-line;
}

.training-ul{
    list-style: disc;
    color: var(--main_font_color);
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; 
    white-space: pre-line;
}





  .our_teem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 50px 180px;
  }

  .our_teem_items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }


  .our_teem .our_teem_item {
    width: 304px;
    height: 235px;
    flex-shrink: 0;
    border-radius: 20px;
    background: rgba(30, 19, 85, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }


  .our_teem .our_teem_item  img{
    width: 105px;
    height: 105px;
    flex-shrink: 0;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 88.5px;
  }
  
  .our_teem .our_teem_item  h5{
    color: var(--main_font_color);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px
  }

  .our_teem_item div{
    display: flex;
    flex-direction: column;
  }


  .our_teem .our_teem_item div span{
    color: var(--main_font_color);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px
  }
  
  .our_teem_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .our-partners{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .our_partner_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .abote_us_order_modal_btn{
    position: fixed;
    z-index: 999999999;
    right: -84px;
    top: 50%;
    display: flex;
    width: 200px;
    height: 43px;
    padding: 20px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px 20px 0 0;
    background: #6644BC;
    transform: rotate(-90deg);
    color: var(--main_font_color);
    cursor: pointer;
  }
  
@media(max-width: 1440px){
    
    .our_teem, .aboute-us-info-block{
        padding: 50px 100px;
    }
}


@media(max-width: 1024px){
    .our_teem, .aboute-us-info-block{
        padding: 50px;
    }
    .aboute-us-info-block{
        width: 90%;
    }
    
}

@media(max-width: 780px){
    .aboute-us-page p , .aboute-us-page ul, .aboute-us-page  .aboute-us-top-div .container p{
        font-size: 14px;
    }

    .aboute-us-page h2{
        font-size: 130px;
    }

    .aboute-us-page h4{
        font-size: 24px;
        width: 400px;
    }

    .aboute-us-page h5{
        font-size: 16px;
    }
}



@media(max-width: 480px){
    .aboute-us-page p , .aboute-us-page ul, .aboute-us-page  .aboute-us-top-div .container p{
        font-size: 14px;
    }

    .aboute-us-page h2{
        font-size: 100px;
    }

    .aboute-us-page h4{
        font-size: 16px;
        width: 350px;
    }

    .aboute-us-page h5{
        font-size: 16px;
    }
}


@keyframes textclip {
    to {
      background-position: -200% center;
    }
  }