.send_message_fixed{
    position: fixed;
    z-index: 99;
    bottom: 200px;
    right: 55px;
    width: 59px;
    height: 59px;
    background-color: #5D64FF;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
}

/* .send_message_fixed >span{} */


.send_message_fixed_block{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send_message_fixed_icons{
    position: absolute;
    top: -240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 222px;
    width: 81px;
    background-color: #AEAEAE1A;
    border-radius: 100px;
    /* backdrop-filter: blur(336px); */
    border: 1px solid #5D64FF

}



@media only screen and (max-width: 480px) {
    .send_message_fixed{
        right: 15px;
        
    }
}

