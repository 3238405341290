.making_games_pages {
   background-color: var(--main_color);
   min-height: 100vh;
}

.making_games_pages .container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   /* align-items: center; */
   overflow: hidden;
   padding: 50px 0;
}

.making_games_pages_top_div{
   width: 100%;
   background-color: #AEAEAE1A;
   /* backdrop-filter: blur(336px); */
   border: 1px solid #5D64FF;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px;
   gap: 50px;
   border-radius: 20px;
}


.making_games_pages_top_div_title{
   color: var(--main_font_color);
   font-weight: 700;
   font-size: 32px;
   text-align: center;
}

.making_games_pages_top_div_img{
   width: 70%;
   height: 571px;
}

.making_games_pages_top_div_img img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}



.making_games_pages_top_div_info{
   width: 70%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}



.making_games_pages_top_div_info >p{
   color: var(--main_font_color);
   font-weight: 500;
}


.making_games_pages_top_div_info_div1{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


.making_games_pages_top_div_info_div2{
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.making_games_pages_top_div_info_div1 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}


.making_games_pages_top_div_info_div1 p{
   color: var(--main_font_color);
   font-weight: 500;
}


.making_games_pages_top_div_info_div2 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}

.making_games_pages_top_div_info_div2 ol{
   display: flex;
   flex-direction: column;
   gap: 15px;
}


.making_games_pages_top_div_info_div2 ol li{
   color: var(--main_font_color);
   font-weight: 500;
}



.makingg_games_page_our_partners .container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 50px;
}

.makingg_games_page_our_partners_title {
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
}


.makingg_games_page_our_partners_title h2{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 28px;
   text-align: center;
}


.makingg_games_page_our_partners_title p{
   color: var(--main_font_color);
   font-weight: 500;
   text-align: center;
}



@media(max-width: 1024px){
   .making_games_pages_top_div_img{
     width: 90%;
     height: 471px;
   }
  
   .making_games_pages_top_div_info{
     width: 90%;
   }
  }
  
  
  
  @media(max-width: 768px){
     .making_games_pages_top_div_img{
       width: 100%;
       height: 371px;
     }
    
     .making_games_pages_top_div_info{
       width: 100%;
     }
  
     .making_games_pages_top_div{
        padding: 50px 20px;
     }
    }
  
  
    @media(max-width: 480px){
     .makingg_games_page_our_partners_title p{
        width: 300px;
     }
  }