.developmen_courses_page_our_advantages{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.developmen_courses_page_our_advantages_block{
    background-color: #5D64FF;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.developmen_courses_page_our_advantages_block .container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.developmen_courses_page_our_advantages_item{
    width: 240px;
    height: 268px;
    border: 1px solid white;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.developmen_courses_page_our_advantages_item_info h3{
    color: var(--main_font_color);
    font-size: 16px;
    font-weight: 900 !important;
}


.developmen_courses_page_our_advantages_item_info p{
    color: var(--main_font_color);
    font-size: 14px;
    font-weight: 400 !important;
}

.developmen_courses_page_our_advantages > h2{
    color: white;
    /* font-size: 24px; */
    font-weight: 900;
    text-align: center;
}

.developmen_courses_page_our_advantages_btn{
    background-color: white;
    border: none;
    outline: none;
    padding: 10px 50px;
    border-radius: 8px;
    color: blue;
    font-weight: 800 !important;
}