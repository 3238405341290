.nav-bar{
    background-color: var(--main_color);
    z-index: 9999;
    position: sticky;
    top: -1px;
}

.nav-bar .container{
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}



.navbar-rigth-div{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  
}





@media(min-width: 1920px){
    .navbar-rigth-div a, #lng{
        font-size: 20px;
        color: white;
    }
  }


@media(max-width: 1920px){
    .navbar-rigth-div a, #lng{
        font-size: 17px;
        color: white;
    }
    .nav-bar .container > a{
        padding: 10px 5px;
        font-size: 16px;
    }
  }

@media(max-width: 1440px){
    .navbar-rigth-div a, #lng{
        font-size: 13px;
        color: white;
    }
    .nav-bar .container > a{
        padding: 10px 5px;
        font-size: 10px;
    }
  }


  @media(max-width: 1140px){
    .navbar-rigth-div a, #lng{
        font-size: 13px;
        color: white;
    }
    .nav-bar .container > a{
        padding: 10px 5px;
        font-size: 10px;
    }
  }


  @media(max-width: 1024px){
    .nav-bar{
        display: none;
    }
  }


 

