.video_courses_section {
   display: flex;
   justify-content: center;
   column-gap: 100px;
   flex-wrap: wrap;
   flex-direction: column;
   align-items: center;
}

.video_courses_section_item_info_div {
   width: 50%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
}


.video_courses_section_item_info_span {
   color: #5D64FF;
   font-weight: 400;
}

.video_courses_section_item_info_span_div {
   display: flex;
   column-gap: 10px;
}

.video_courses_section_h2 {
   text-align: center;
   padding-bottom: 50px;
   color: var(--main_font_color);
   font-weight: 600;
}

.development_courses_cycles_item_info_p{
   color: var(--main_font_color);
   font-weight: 400;
   text-align: center;
}

.video_courses_section_cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
   row-gap: 30px;
   column-gap: 30px;
   justify-content: center;
   align-items: start;
   width: 100%;
   padding-top: 50px;
}

@media (max-width: 768px) {
   .video_courses_section_item_info_div {
      width: 80%;
   }
}