.training-cards-all{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.training-cards{
  width: 304px;
  height: 200px;
  /* background-color: goldenrod; */
  background: linear-gradient(117.58deg, #9352b7 2.04%, #7b36a1 108.32%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  border-radius: 20px;
  cursor: pointer;
}
.training-cards-blue{
  width: 304px;
  height: 200px;
  /* background-color: goldenrod; */
  background: linear-gradient(114.9deg, #4542bf 10.66%, #2c2a96 99.42%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  border-radius: 20px;
  cursor: pointer;
}
@media screen and (max-width: 798px) {
  .training-cards-all{
    justify-content: center;
  }
}