.tools-info-item{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    padding: 15px;
    padding-top: 20px;
}

.tools-info-item p{
    color: var(--main_font_color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.tools-info-item span{
    height: 60px;
}

@media (max-width: 1440px){
    .tools-info-item{
        width: 142px;
        height: 142px;
    }
    .tools-info-item p{
        font-size: 15px;
    }

}

@media (max-width: 1140px){
    .tools-info-item{
        width: 130px;
        height: 130px;
    }
    .tools-info-item p{
        font-size: 13px;
    }

}


