.allSide{
  display: flex;
  /* justify-content: space-around; */
  gap: 34px;
  background-color: #F4F7F9;
  padding: 17px 0 20px 0;
/* padding-top: 40px;   */
}

.leftSide{
  padding: 0 0 0 40px;
}

.leftSideTop{
  width: 58vw;
}
/* .leftSideTop{
  position: relative;
  width: 100%; 
   margin-left: 100px;
} */

/* .leftSideTop::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #FFFFFF;
  width: 84vw;
} */
.title{
  font-size: 48px;
  font-weight: 700;
  line-height:58.59px;
}
.title_name{
  font-size: 32px;
  font-weight: 700;
  line-height:39.06px;
  color: #FFFFFF;
  margin: 0;
}
.Introduction{
  font-size: 24px;
  font-weight: 400;
  line-height: 29.3px;
  /* margin-bottom: 50px; */
  margin-top: 0px;
  margin-bottom: 12px;
  color: #000000;
}
.allvideoLessons{
   display: flex;
   align-items: center;
   gap: 28px;
   padding-top: 24px;
   cursor: pointer;
}
.rightSide{
  background-color: rgb(152, 106, 198);
  padding: 24px 42px;
  height: max-content;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 0px #0000001A;
  background-color: #FFFFFF;
  width: 22vw;
}
.titleAllVideo{
  color: #000000;
  line-height: 29.3px;
  font-size: 22px;
  font-weight: 400;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 9px;
}

.nameAllVideo{
  line-height: 24.41px;
  font-size: 18px;
  font-weight: 400;
  color: #A7A7A7;
}
.nameAllVideo-Active{
  color: #4D4BA4;
}

.description{
 background-color: #FFFFFF;
 /* width: 100%; */
 padding: 24px 24px 24px 24px;
 border-radius: 6px;
 margin-top: 30px;
 box-shadow: 0px 2px 6px 0px #0000001A;
cursor: pointer;
}
.description_title{
  font-size: 20px;
  font-weight: 500;
  line-height: 29.3px;
  color: #000000;
  margin-bottom: 20px;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 8px;
  margin-top: 0;
}
.description_text{
  font-size: 16px;
  line-height: 24.41px;
  font-weight: 400;
  color: #5F5F60;
}
.description_more{
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
}

.homework_linne{
  background-color: #FFFFFF;
  /* width: 100%; */
  padding: 24px 24px 24px 24px;
  border-radius: 6px;
  margin-top: 30px;
  box-shadow: 0px 2px 6px 0px #0000001A;
  cursor: pointer;
}
.homework_title{
  font-size: 20px;
  font-weight: 500;
  line-height: 29.3px;
  color: #000000;
  margin-bottom: 20px;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 8px;
  margin-top: 0;
}
.homework_linne_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5F5F60;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 8px;
  margin-bottom: 18px;
}
.homework_text{
  font-size: 16px;
  line-height: 24.41px;
  font-weight: 400;
}

.homework_side{
  background-color: #FFFFFF;
  /* width: 100%; */
  padding: 24px 24px 24px 24px;
  border-radius: 6px;
  margin-top: 30px;
  box-shadow: 0px 2px 6px 0px #0000001A;
}
.HomeworkList{
  font-size: 20px;
  font-weight: 500;
  line-height: 29.3px;
  color: #000000;
  margin-bottom: 20px;
  border-bottom: 1px solid #5F5F60;
  padding-bottom: 8px;
  margin-top: 0;
}
/* .homework_line{
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 40px;
  padding-bottom: 25px;
}
.homework_line_p{
  font-size: 21px;
  line-height: 29.3px;
  font-weight: 700;
  color: #FFFFFF;
} */

.vector222{
  width: 24px;
  height: 30px;
}
.vector222Down{
  width: 18px;
  height: 17px;
}
.vector222Down-Active{
  width: 24px;
  height: 23px;
  filter: brightness(0) saturate(100%) invert(27%) sepia(65%) saturate(2298%) hue-rotate(229deg) brightness(76%) contrast(93%);
}

.homeworkes_linne_div{
  cursor: pointer;
}
.message-modal{
    display: flex;
    justify-content: center;
    align-items: flex-start;                                                         
    position: fixed;                                                             
    z-index: 999999999999999999999;                         
    left: 0;                                                                                           
    top: 0;                                                                                            
    width: 100%; /* Full width */                                                                      
    height: 100%; /* Full height */                                                                    
    overflow-y: auto;
    background-color: transparent;
    padding: 100px 0;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

@media screen and (max-width: 900px) {
  .allSide {
    gap: 20px;
  }
  .titleAllVideo{
    line-height: 24.3px;
    font-size: 17px;
  }
  .rightSide{
    padding: 21px 22px;
    width: 20vw;
  }
  .nameAllVideo{
    font-size: 16px;
  }
}
@media screen and (max-width: 850px) {
  .allSide{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leftSide{
    padding: 0;
  }
  .rightSide{
    padding: 21px 22px;
    width: 76vw;
  }
  .leftSideTop{
    width: 76vw;
  }
}

@media screen and (max-width: 650px) {
  .description_title{
    font-size: 17px;
  }
  .homework_title{
    font-size: 17px;
  }
  .HomeworkList{
    font-size: 17px;
  }

}