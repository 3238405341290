* {
   /* font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif; */
   font-family: 'font-1';
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   /* letter-spacing: 1px; */
   font-weight: 100 !important;
}

@font-face {
   font-family: 'TitleFont';
   src: url('../src/fonts/BraindGyumri.otf') format('truetype');
}

.container {
   max-width: 1920px ;
   /* margin: 0 auto; */
   padding: 0 50px;
   box-sizing: border-box;
}

ul {
   list-style: none;
   color: var(--main_font_color);
}

.App a {
   text-decoration: none;
   color: white;
}

:root {
   --main_color: #010526;
   --main_font_color: #d5d5d5;
}

@font-face {
   font-family: font-1;
   src: url(./fonts/Roboto-Light.ttf);
}

/*
 @media(min-width: 1920px){
  .container{
    max-width: 100%;
    padding: 0 100px;
  }
} 

 @media(max-width: 1440px){
  .container{
    max-width: 1440px;
  }
}

@media (max-width: 1920px) {
  .container {
    max-width: 1920px;
  }
} */

@media (max-width: 1140px) {
   .container {
      max-width: 1140px;
      padding: 0 20px;
   }
}
