.registre_full_div {
   min-height: 100vh;
   padding-bottom: 100px;
}

.login {
   background-color: var(--main_color);
   /* min-height: 100vh; */
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 100px 0;
   /* min-height: 100vh; */
}

.login .container {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   height: 100%;
   column-gap: 100px;
}

.login .container form {
   display: flex;
   flex-direction: column;
   row-gap: 30px;
   align-items: center;
}

.login .container form input {
   width: 383px;
   height: 40px;
   border: 1px solid white;
   background-color: transparent;
   padding: 0 10px;
   outline: none;
   color: var(--main_font_color);
   font-size: 16px !important;
   border-radius: 8px;
}

.login .container form p {
   color: red;
   font-size: 12px;
   padding-top: 10px;
   margin: 0;
}

.login .container form h2 {
   color: var(--main_font_color);
   font-size: 25px;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
}

.login .container form h6,
.login .container form h5 {
   color: var(--main_font_color);
   font-size: 16px;
   font-style: normal;
   line-height: normal;
   margin-block-start: 0;
   margin-block-end: 0;
}

.login .container form h6 a,
.login .container form h5 span {
   color: var(--main_font_color);
   font-size: 18px;
   font-style: normal;
   line-height: normal;
   text-decoration-line: underline;
   cursor: pointer;
}

.btn_dv button {
   color: white;
   text-align: center;
   font-size: 14px;
}

.btn_dv >p{
   color: var(--main_font_color)
}

.log_img_div {
   width: 780px;
   height: 400px;
}
.log_img_div > canvas {
   width: 100% !important;
   height: 100% !important;
}
.title_texth1 {
   width: 90%;
   color: white;
   text-align: center;
   padding-bottom: 70px;
}
.register_drop_down {
   padding-left: 100px;
   width: 93%;
   max-height: calc(300px - 50px);
   overflow-y: auto;
   max-height: 450px;
}
.dtoptext_ul {
   color: white;
}
.register_drop_down::-webkit-scrollbar {
   width: 5px;
}

.register_drop_down::-webkit-scrollbar-track {
   background: rebeccapurple;
}

.register_drop_down::-webkit-scrollbar-thumb {
   background: rgb(160, 125, 196);
}
.register_drop_down::-webkit-scrollbar-thumb:hover {
   background: rebeccapurple;
}

.btn_dv button img {
   width: 20px;
   height: 20px;
}
.div_ul_task {
   padding-bottom: 20px;
}

.task_ul {
   color: white;
   list-style-type: square;
   padding-left: 50px;
   margin-bottom: 0;
   min-height: 70px;
}

@media (max-width: 1024px) {
   .log_img_div {
      width: 600px;
      height: 300px;
   }
   .login .container {
      flex-direction: column-reverse;
   }
}

@media (max-width: 720px) {
   .log_img_div {
      width: 400px;
      height: 200px;
   }
   .login .container {
      flex-direction: column-reverse;
   }

   .login .container form h2 {
      font-size: 20px;
   }
   .login {
      padding: 30px 20px;
   }
}

@media (max-width: 480px) {
   .log_img_div {
      width: 300px;
      height: 200px;
   }
   .login .container {
      flex-direction: column-reverse;
      padding: 0;
   }

   .login .container form h2 {
      font-size: 20px;
   }
   .login {
      padding: 30px 20px;
   }

   .login .container form input {
      width: 300px;
   }
}
