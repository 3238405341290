.news_main_page {
   background-color: var(--main_color);
   padding: 50px 0;
   min-height: 100vh;
}

.news_main_page .container {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   padding: 0 180px;
}

.news_main_page .container .category-top-div {
   height: 900px;
   width: 1190px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.category-top-div h3 {
   cursor: pointer;
   filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg)
      brightness(112%) contrast(99%);
   color: white;
}

.news_main_page .container .category-top-div .category-top-div-block {
   display: flex;
   justify-content: center;
   gap: 30px;
   width: 100%;
   height: 100%;
}

.news_main_page .container .category-top-div > div {
   width: 45%;
   height: 100%;
}

.news_main_page .container .category-top-div .category-top-div-big-div {
   display: flex;
   flex-direction: column;
   gap: 20px;
   align-items: center;
}

.news_main_page
   .container
   .category-top-div
   .category-top-div-big-div
   .category-top-div-big-div-info {
   display: flex;
   flex-direction: column;
   color: white;
   gap: 10px;
}
.category-top-div-big-div-info h4 {
   color: white;
}
.category-top-div-big-div-info p {
   color: white;
}

.category-top-div-big-div-img-div {
   width: 100%;
   height: 50%;
   overflow: hidden;
}
.news_main_page .container .category-top-div .category-top-div-big-div img {
   width: 100%;
   height: 100%;
   border-radius: 0;
   transition: all 1s;
}
.news_main_page .container .category-top-div .category-top-div-big-div img:hover {
   transform: scale(1.2);
}

.news_main_page .container .category-top-div .category-top-div-big-div p {
   font-size: 16px;
   display: -webkit-box;
   -webkit-line-clamp: 6;
   /* Number of lines to show before truncating with ellipsis */
   -webkit-box-orient: vertical;
   overflow: hidden;
   position: relative;
   color: white;
}

.news_main_page .container .category-top-div .category-top-div-big-div p::after {
   content: '...';
   position: absolute;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 1.5em;
}

.news_main_page .container .category-top-div .category-top-div--small-div {
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.news_main_page .container .category-top-div .category-top-div--small-div img {
   width: 150px;
   height: 150px;
   border-radius: 0;
}

.news_main_page .container .category-top-div .category-top-div--small-div p {
   font-size: 14px;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   /* Number of lines to show before truncating with ellipsis */
   -webkit-box-orient: vertical;
   overflow: hidden;
   position: relative;
}

.category-top-div-small-div-item-info-share-and-date-div > span {
   color: white;
   font-size: 14px;
}

.news_main_page .container .category-top-div .category-top-div--small-div p::after {
   content: '...';
   position: absolute;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 1.5em;
}

.category-top-div-small-div-item {
   display: flex;
   gap: 20px;
}

.category-top-div-small-div-item-info {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.category-top-div-small-div-item-info >p{
   color: var(--main_font_color);
}

.category-top-div-big-div-info-share-and-date-div {
   display: flex;
   gap: 20px;
   align-items: center;
}

.category-top-div-big-div-info-share-and-date-div > span {
   border-right: 1px solid gray;
   padding-right: 20px;
}

.category-top-div-small-div-item-info-share-and-date-div {
   display: flex;
   gap: 20px;
   align-items: center;
}

.category-top-div-small-div-item-info-share-and-date-div > span {
   border-right: 1px solid gray;
   padding-right: 20px;
}

@media (max-width: 1024px) {
   .news_main_page .container .category-top-div {
      width: 700px;
      height: 600px;
   }
   .news_main_page .container .category-top-div .category-top-div--small-div img {
      width: 125px;
      height: 125px;
   }
   .news_main_page {
      gap: 100px;
   }

   .category-top-div h3 svg {
      width: 550px;
   }
}

@media (max-width: 780px) {
   .news_main_page .container .category-top-div {
      width: 400px;
      height: 1100px;
   }

   .news_main_page .container .category-top-div .category-top-div-block {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
   }

   .news_main_page .container .category-top-div .category-top-div-big-div {
      height: 450px;
   }

   .category-top-div h3 svg {
      width: 400px;
   }
}

@media (max-width: 480px) {
   .news_main_page .container .category-top-div {
      width: 300px;
      height: 1100px;
   }

   .news_main_page .container .category-top-div .category-top-div-block {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
   }

   .news_main_page .container .category-top-div .category-top-div-big-div {
      height: 450px;
   }

   .category-top-div h3 svg {
      width: 300px;
   }
}

.load-div {
   width: 100vw;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 9999;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
}

.loader {
   width: 48px;
   height: 48px;
   border: 5px solid black;
   border-bottom-color: transparent;
   border-radius: 50%;
   display: inline-block;
   box-sizing: border-box;
   animation: rotation 1s linear infinite;
}

@keyframes rotation {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
