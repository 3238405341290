@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.burger-menu {
  display: none;
}
.wrapper{
  height: 100%;
  width: 350px;
  position: relative;
  z-index: 99;
}
.wrapper .menu-btn{
  position: fixed;
  left: 20px;
  top: 7px;
  background: #2D318A;
  color: var(--main_font_color);
  height: 35px;
  width: 35px;
  z-index: 9999;
  /* box-shadow: 0 0 10px #4c53d3; */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
#btn:checked ~ .menu-btn{
  left: 247px;
}
.wrapper .menu-btn svg{
  position: absolute;
  /* transform: ; */
  font-size: 23px;
  transition: all 0.3s ease;
}
.wrapper .menu-btn svg.fa-times{
  opacity: 0;
}
#btn:checked ~ .menu-btn svg.fa-times{
  opacity: 1;
  transform: rotate(-180deg);
}
#btn:checked ~ .menu-btn svg.fa-bars{
  opacity: 0;
  transform: rotate(180deg);
}
#sidebar{
  position: fixed;
  background: #6644BC;
  height: 100%;
  width: 300px;
  overflow: hidden;
  left: -300px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 30px 0;
  overflow-y: auto;
}
#btn:checked ~ #sidebar{
  left: 0;
}
#sidebar .title{
  line-height: 65px;
  text-align: center;
  background: #333;
  font-size: 25px;
  font-weight: 600;
  color: var(--main_font_color);
  border-bottom: 1px solid #222;
}
#sidebar .list-items{
  /* position: relative; */
  background: #6644BC;
  width: 100%;
  /* height: 100%; */
  list-style: none;
  padding-inline-start: 0;
  
}
#sidebar .list-items li{
  padding-left: 40px;
  line-height: 50px;
  border-top: 1px solid ;
  border-bottom: 1px solid #31348E;
  transition: all 0.3s ease;
  width: 100%;
}
#sidebar .list-items li:hover{
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: 0 0px 10px 3px #222;
}
#sidebar .list-items li:first-child{
  border-top: none;
}
#sidebar .list-items li a{
  color: var(--main_font_color);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  display: block;
  padding: 20px 0;
  border: none;
}
#sidebar .list-items li a svg{
  margin-right: 20px;
}
#sidebar  .icons{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 20px;
}
#sidebar .icons .my-reg_log_class a:nth-of-type(1){
  height: 100%;
  width: 40px;
  display: block;
  margin: 0 5px;
  font-size: 18px ;
  color: var(--main_font_color);
  background: #2D318A;
  border-radius: 5px;
  box-shadow: 0 0 10px #4c53d3;
  transition: all 0.3s ease;
}
#sidebar  .icons .my-reg_log_class a:nth-of-type(1){
    background: #2D318A;
}
 .icons a:first-child{
  margin-left: 0px;
}
.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #202020;
  z-index: -1;
  width: 100%;
  text-align: center;
}
.content .header{
  font-size: 45px;
  font-weight: 700;
}
.content p{
  font-size: 40px;
  font-weight: 700;
}




.logo-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}


.my-reg_log_class, .my-leng_class{
    display: flex;
    /* column-gap: 20px; */
}

.my-leng_class{
  margin-left: 20px;
  display: flex;
  padding-bottom: 100px;
}

.my-leng_class ul{
  line-height: 22px;
  left: 30px;
}

.list-items li.drop-item{
  position: relative;
  padding: 0 !important;
}


.drop-menu{
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color:  #050a3a;
  backdrop-filter: blur(10px);
  transform: rotateX(90deg);
  transition: all .5s;
  transform-origin: top center;
  visibility: hidden;
  z-index: 999;
  padding: 0;
}

.drop-item:hover ul{
  transform: rotateX(0);
  visibility: visible;
}

#sidebar .list-items .drop-item .drop-menu li a{
  font-size: 14px;
}

.registr_and_login_buttons_div{
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.burger-menu .user-div{
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
}

.burger-menu .user-div span{
  color: var(--main_font_color);
}

 .burger-menu .user-div img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}




@media (max-width: 480px){
  /* .my-reg_log_class{
    display: none;
  } */
  .burger-menu .user-div{
    display: none;
  }
}


@media(max-width: 1024px){
  .burger-menu{
      display: block;
  }
} 

@media(max-width: 850px){
  .wrapper .menu-btn{
    left: 12px;
  }
} 