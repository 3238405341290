.item-div{
    width: 180px;
    height: 110.001px;
    position: absolute;
    border-radius: 20px;
    cursor: pointer;
  
}




.item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 20px;
}


.item:hover {
    /* outline: 3px solid white; */
    box-shadow: 0 0 15px white;
    transition: all 1s;
}

 


.item span{
    color: var(--main_font_color);
    text-align: center;
    text-shadow: 2px 2px 4px rgba(1, 5, 38, 0.75);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.item-div .item +svg{
    position: absolute;
}

.item-div .item:hover +svg path{
    stroke-width: 3px;
    transition: all 2s; 
 } 

.item-div .item:hover  +svg stop {
     stop-color: #58FFFE; 
     /* animation: lineAnimation 7s forwards;  */
    animation: lineAnimation 1s ease-in-out forwards;
}




/* Item-1 start */
.item-div:nth-of-type(1){
    top: -300px;
    left: 0;
}

.item-div:nth-of-type(1) .item +svg{
   left: -60px;
   top: 108px;
}


/* Item-1 end */



/* Item-2 start */

.item-div:nth-of-type(2){
    top: -200px;
    left: 300px;
}

.item-div:nth-of-type(2) .item +svg{
    right: 133px;
    top: 110px;
 }


/* Item-2 end */


/* Item-3 start */
.item-div:nth-of-type(3){
    top: 0;
    left: 400px;
}

.item-div:nth-of-type(3) .item  +svg{
    right: 135px;
    top: 130px;
    transform: rotate(-7deg);
 }

/* Item-3 end */


/* Item-4 start */
.item-div:nth-of-type(4){
    top: 200px;
    left: 300px;
}

.item-div:nth-of-type(4) .item  +svg{
    top: -256px;
    left: -220px;
    transform: rotate(-2deg);
 }

/* Item-4 end */

/* Item-5 start */
.item-div:nth-of-type(5){
    bottom: -300px;
    left: 0;
}

.item-div:nth-of-type(5) .item  +svg{
    top: -248px;
    left: 92px;
    /* transform: rotate(20); */
 }

 /* Item-5 end */

 /* Item-6 start */
.item-div:nth-of-type(6){
    top: 200px;
    left: -300px;
    z-index: 2;
}

.item-div:nth-of-type(6) .item  +svg{
    top: -207px;
    left: 125px;
 }

/* Item-6 end */

/* Item-7 start */
.item-div:nth-of-type(7){
    top: 0;
    left: -400px;
}

.item-div:nth-of-type(7) .item  +svg{
    top: 120px;
    left: 100px;
    transform: rotate(4deg);
 }

/* Item -7 end */

/* Item -8 start */
.item-div:nth-of-type(8){
    top: -200px;
    left: -300px;
}


.item-div:nth-of-type(8) .item  +svg{
    top: 120px;
    left: 93px;
    transform: rotate(4deg);
 }



 @keyframes lineAnimation {
    0% {
        stop-color:white 
    }
    50%{
        stop-color: white

    }

    100%{
        stop-color:white

    }
}






/* ========================media===================== */



@media(max-width: 1440px){
    .item-div{
        width: 160px;
        height: 90.001px;
    }

    .item span{
        font-size:18px ;
    }

    /* Item-1 start */
.item-div:nth-of-type(1){
    top: -300px;
    left: 0;
}

.item-div:nth-of-type(1) .item +svg{
   left: -55px;
   top: 90px;
}


/* Item-1 end */



/* Item-2 start */

.item-div:nth-of-type(2){
    top: -200px;
    left: 300px;
}

.item-div:nth-of-type(2) .item +svg{
    right: 117px;
    top: 91px;
 }
/* Item-2 end */


/* Item-3 start */
.item-div:nth-of-type(3){
    top: 0;
    left: 400px;
}

.item-div:nth-of-type(3) .item  +svg{
    right: 110px;
    top: 120px;
    transform: rotate(-10deg);
 }

/* Item-3 end */


/* Item-4 start */
.item-div:nth-of-type(4){
    top: 200px;
    left: 300px;
}

.item-div:nth-of-type(4) .item  +svg{
    top: -256px;
    left: -220px;
    transform: rotate(-2deg);
 }

/* Item-4 end */

/* Item-5 start */
.item-div:nth-of-type(5){
    bottom: -300px;
    left: 0;
}

.item-div:nth-of-type(5) .item  +svg{
    top: -247px;
    left: 80px;
    
 }

 /* Item-5 end */

 /* Item-6 start */
.item-div:nth-of-type(6){
    top: 200px;
    left: -300px;
    z-index: 2;
}

.item-div:nth-of-type(6) .item  +svg{
    top: -207px;
    left: 125px;
 }

/* Item-6 end */

/* Item-7 start */
.item-div:nth-of-type(7){
    top: 0;
    left: -400px;
}

.item-div:nth-of-type(7) .item  +svg{
    top: 110px;
    left: 100px;
    transform: rotate(8deg);
 }

/* Item -7 end */

/* Item -8 start */
.item-div:nth-of-type(8){
    top: -200px;
    left: -300px;
}


.item-div:nth-of-type(8) .item  +svg{
    top: 95px;
    left: 93px;
    transform: rotate(4deg);
 }
}



@media(max-width: 1024px){
    .item-div{
        width: 100px;
        height: 50.001px;
        border-radius: 10px;
    }
    .item{
        border-radius: 10px;
    }

    .item span{
        font-size:12px ;
    }

    .item-div .item +svg{
        height: 93px;
    }

    /* Item-1 start */
.item-div:nth-of-type(1){
    top: -130px;
    left: 0;
}

.item-div:nth-of-type(1) .item +svg{
   left: -55px;
   top: 52px;
}


/* Item-1 end */



/* Item-2 start */

.item-div:nth-of-type(2){
    top: -70px;
    left: 160px;
}

.item-div:nth-of-type(2) .item +svg{
    right: 17px;
    top: 38px;
    transform: rotate(15deg);
 }
/* Item-2 end */


/* Item-3 start */
.item-div:nth-of-type(3){
    top: 30px;
    left: 220px;
}

.item-div:nth-of-type(3) .item  +svg{
    right: -20px;
    top: 58px;
    transform: rotate(-4deg);
 }

/* Item-3 end */


/* Item-4 start */
.item-div:nth-of-type(4){
    top: 130px;
    left: 160px;
}

.item-div:nth-of-type(4) .item  +svg{
    top: -100px;
    left: -153px;
    transform: rotate(8deg);
 }

/* Item-4 end */

/* Item-5 start */
.item-div:nth-of-type(5){
    bottom: -150px;
    left: 0;
}

.item-div:nth-of-type(5) .item +svg{
    height: 130px;
    top: -130px;
    left: 22px;
 }

 /* Item-5 end */

 /* Item-6 start */
.item-div:nth-of-type(6){
    top: 130px;
    left: -170px;
    z-index: 2;
}

.item-div:nth-of-type(6) .item  +svg{
    top: -99px;
    left: 20px;
    transform: rotate(-7deg);
 }

/* Item-6 end */

/* Item-7 start */
.item-div:nth-of-type(7){
    top: 20px;
    left: -230px;
}

.item-div:nth-of-type(7) .item  +svg{
    top: 60px;
    left:-10px;
    transform: rotate(6deg);
 }

/* Item -7 end */

/* Item -8 start */
.item-div:nth-of-type(8){
    top: -70px;
    left: -170px;
}


.item-div:nth-of-type(8) .item  +svg{
    top: 50px;
    left: 18px;
    transform: rotate(-1deg);
 }
}




@media(max-width: 720px){
    .item-div{
        width: 100px;
        height: 50.001px;
        border-radius: 10px;
    }
    .item{
        border-radius: 10px;
    }

    .item span{
        font-size:12px ;
    }

    .item-div .item +svg{
        height: 93px;
    }

    .item-div{
        position: static;
    }

    .item-div .item +svg{
        position: static;
    }

    .item-div .item +svg {
        display: none;
    }


}
