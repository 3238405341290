.faq_component .heading {
    color: white;
    font-weight: 900 !important;
    margin-bottom: 2rem;
    text-align: center;
  }
  
 .faq_component .container {
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
 .faq_component form {
    display: flex;
    width: 35rem;
    background-color: #fff;
    align-items: center;
    margin: 0 auto;
    border-radius: 1rem;
  }
  

  
 .faq_component .faq {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
 .faq_component .question-wrapper {
    width: 60%;
    border: 1px solid #5D64FF;
    margin: 0 auto;
    padding: 20px 10px;
    border-radius: 8px;
    transition: 1s;
    cursor: pointer;
  }
  
 .faq_component .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  
 .faq_component .question h3 {
    font-size: 16px;
    font-weight: 900 !important;
    color:  white;
  }
  
 .faq_component .question svg {
    width: 1rem;
    height: 1.5rem;
    fill: #5D64FF;
  }
  
 .faq_component .question svg.active {
    transform: rotate(180deg);
  }
  
 .faq_component .question svg:hover {
    opacity: 0.8;
  }
  
 .faq_component button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
 .faq_component button:focus {
    outline: none;
  }
  
 .faq_component .answer {
    display: none;
  }
  
 .faq_component .answer.active {
    display: block;
    text-align: left;
    padding-top: 1.5rem;
    font-size: 0.8rem;
    line-height: 1.5;
    color: var(--main_font_color);
    font-size: 16px;
    font-weight: 400!important;
    /* height: 0%; */
    animation: slidein 0.4s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slidein {
    from {
      opacity: 0.3;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  

  @media(max-width: 1024px){
    .faq_component .question-wrapper{
        width: 90%;
    }
  }