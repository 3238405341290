.private-project{
    padding: 100px 0;
    position: relative;
}


.private-project-items{
    position: relative;
    background-color: #FA44DD0D;
    padding: 80px 30px;
    padding-bottom: 110px;
    border-radius: 26px;
}

.private-project-items .react-multi-carousel-dot-list {
  bottom: 20px;
}

.private-project-item{
  width: 321px;
  border-radius: 20px;
  background-color: #ffff;
}

.private-project-item-img{
  width: 100%;
  height: 381px;
  position: relative;
  border-radius: 20px;
}


.private-project-title{
  color: white;
  text-align: center;
  padding-bottom: 50px;

}

.private-project-item-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

}

.private-project-item-info{
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

div.private-project-item-info b{
  color: #7977A3;
  font-weight: 900 !important;
  font-size: 18px;
  text-align: center;
}

.private-project-item-info span{
  color: #7977A3;
}

.private-project-item-btns{
  display: flex;
  gap: 10px;
}

.private-project-item-btns .example-btn{
  padding: 5px 30px;
  background-color:#5D64FF ;
  color: white;
  border: none;
  outline: none;
  border-radius: 8px;
}

.private-project-item-btns .example-btn:hover{
  background-color: #424AFF;
}

.private-project-item-btns .order-btn{
  padding: 5px 30px;
  background-color:#424AFF ;
  color: white;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: all .3s;
}

.private-project-item-btns .order-btn:hover{
  background-color: #2B33E0;
}

.private-project-item-img-shadow{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 32%, rgba(0,0,0,0) 100%);
}

/* .private_project_view_more{
  text-align: center;
  margin-top: 50px;
  background-color: #5D64FF;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  outline: none;
  border-radius: 8px;
  margin-left:50%;
  transform: translateX(-50%);
}

.private_project_view_more:hover{
  background-color: #424AFF;
} */

.private-project >a{
  margin-top: 50px;
  margin-left:50%;
  transform: translateX(-50%);
}