.send_message_feedback{
    position: absolute;
    top: -400px;
    right: 100px;
    background-color: #AEAEAE1A;
    border: 1px solid #5D64FF;
    backdrop-filter: blur(336px);
    width: 326px;
    z-index: 9999999;
    border-radius: 8px;
}

.send_message_feedback_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    gap: 20px;
}

.send_message_feedback_form >div{
    width: 100%;
}

.send_message_feedback_form >h4{
    color: var(--main_font_color);
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

.send_message_feedback_form input{
    width: 100%;
    height: 48px;
    border: 1px solid #5D64FF;
    background-color: transparent;
    padding-left: 10px;
    border-radius: 8px;
    color: white;
}

.send_message_feedback_form textarea{
    width: 100%;
    height: 121px;
    border: 1px solid #5D64FF;
    background-color: transparent;
    padding-left: 10px;
    border-radius: 8px;
    color: white;
}

.send_message_feedback_form input::placeholder, .send_message_feedback_form textarea::placeholder{
    color: white;
}

.send_message_btn_email{
    width: 100%;
    height: 48px;
    background-color: #5D64FF;
    color: white;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
}

.send_message_btn_email:hover{
    background-color: #424AFF;
}


.send_message_feedback_form .error{
    color: yellow;
    font-size: 13px;
}

.send_message_feedback_success{
    color: white;
    font-size: 13px;
}


@media only screen and (max-width: 480px) {
    .send_message_feedback{
        width: 250px;
        top:-370px ;
    }

    .send_message_feedback_form input{
        height: 30px;
    }

    .send_message_feedback_form textarea{
        height: 60px;
    }
}



@media (max-height: 920px){
    .send_message_feedback{
        top: -350px;
    }
}


@media (max-height: 720px){
    .send_message_feedback{
        top: -300px;
    }
}