.message_modal{
    display: flex;
    justify-content: center;
    align-items: center;                                                         
    position: fixed;                                                             
    z-index: 999999999999999999999;                         
    left: 0;                                                                                           
    top: 0;                                                                                            
    width: 100%; /* Full width */                                                                      
    height: 100%; /* Full height */                                                                    
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.64);
    padding: 100px 0;
}

.message_modal-block{
    width: 40%;
    background-color: #010526;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:  50px;
    animation: modalOrderAnim .5s;
    overflow-y: auto;
    gap: 30px;
}

.message_modal-block p{
    color: var(--main_font_color);
}

@media(max-width: 1140px){
    .message_modal-block{
        width: 70%;
    }
}

@media(max-width: 720px){
    .message_modal-block{
        width: 90%;
    }
}   

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 