.modeling3D {
   background-color: var(--main_color);
   min-height: 100vh;
}

.modeling3D .container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   /* align-items: center; */
   overflow: hidden;
   padding: 50px 0;
}

.modeling3D_top_div{
   width: 100%;
   background-color: #AEAEAE1A;
   /* backdrop-filter: blur(336px); */
   border: 1px solid #5D64FF;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px;
   gap: 50px;
   border-radius: 20px;
}


.modeling3D_top_div_title{
   color: var(--main_font_color);
   font-weight: 700;
   font-size: 32px;
   text-align: center;
}

.modeling3D_top_div_img{
   width: 70%;
   height: 571px;
}

.modeling3D_top_div_img img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}



.modeling3D_top_div_info{
   width: 70%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}



.modeling3D_top_div_info >p{
   color: var(--main_font_color);
   font-weight: 500;
}


.modeling3D_top_div_info_div1{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


.modeling3D_top_div_info_div2{
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.modeling3D_top_div_info_div1 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}


.modeling3D_top_div_info_div1 p{
   color: var(--main_font_color);
   font-weight: 500;
}


.modeling3D_top_div_info_div2 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}

.modeling3D_top_div_info_div2 ol{
   display: flex;
   flex-direction: column;
   gap: 15px;
}


.modeling3D_top_div_info_div2 ol li{
   color: var(--main_font_color);
   font-weight: 500;
}


.modeling3D_example_section .container{
   display: flex;
   flex-direction: column;
   gap: 50px;
   justify-content: center;
   align-items: center;
}

.modeling3D_example_section_item{
   width: 80%;
}

.modeling3D_example_section .container >h2{
   color: white;
   font-weight: 900 !important;
   font-size: 28px;
   line-height: 56px;
   text-align: center;
}


.modeling3D_page_our_partners .container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 50px;
}

.modeling3D_page_our_partners_titles {
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
}


.modeling3D_page_our_partners_titles h2{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 28px;
   text-align: center;
}


.modeling3D_page_our_partners_titles p{
   color: var(--main_font_color);
   font-weight: 500;
   text-align: center;
}



@media(max-width: 1024px){
   .modeling3D_top_div_img{
     width: 90%;
     height: 471px;
   }
  
   .modeling3D_top_div_info{
     width: 90%;
   }
  }
  
  
  
  @media(max-width: 768px){
     .modeling3D_top_div_img{
       width: 100%;
       height: 371px;
     }
    
     .modeling3D_top_div_info{
       width: 100%;
     }
  
     .modeling3D_top_div{
        padding: 50px 20px;
     }
    }
  
  
    @media(max-width: 480px){
     .modeling3D_page_our_partners_titles p{
        width: 300px;
     }
  }