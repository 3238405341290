.error_all {
  display: flex;
  justify-content: center; 
  align-items: center; 
  background-color: #010526;
  height: 100vh; 
}

.error_img {
  text-align: center;
  background-color: #010526;
}

.errImg {
  max-width: 100%; 
  height: auto;
}

.error_title{
  color: #FFFFFFF2;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.83px;
}
.error_text{
  color: #FFFFFFF2;
  font-size: 26px;
  font-weight: 400;
  line-height: 31.74px;
  margin-bottom: 50px;
}


@media screen and (max-width: 600px) {
  .error_title{
    font-size: 26px;
  }
  .error_text{
    font-size: 17px;
  }
}