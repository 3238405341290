.reset-password-page{
    background-color: var(--main_color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.reset-password-page .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reset-password-page .container h5{
    color: var(--main_font_color);
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}

.reset-password-page .container .reset-password-form{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
}

.reset-password-page .container .reset-password-form input {
    width: 383px;
    height: 40px;
    border: 1px solid white;
    background-color: transparent;
    padding: 0 10px;
    outline: none;
    color: var(--main_font_color);
    font-size: 16px !important;
}



.reset-password-page .container .reset-password-form p{
    color: red;
    font-size: 12px;
    padding-top: 10px;
    margin: 0;
}

.reset-password-form div{
    position: relative;
}
.reset-password-form div span{
    position: absolute;
    top: 5px;
    right: 5px;
}




@media(max-width:1024px){
   
}

@media(max-width:480px){
   
    .reset-password-page .container .reset-password-form input{
        width: 300px;
    }
}


@keyframes modalAnim {
    to{
        transform: scale(1);
    }
    from{
        transform: scale(0);
    }
}