.service_and_support{
   background-color: var(--main_color);
   min-height: 100vh;
}


.service_and_support .container {
   display: flex;
   justify-content: center;
   flex-direction: column;
   /* align-items: center; */
   overflow: hidden;
   padding: 50px 0;
}

.services_and_support_top_div{
   width: 100%;
   background-color: #AEAEAE1A;
   /* backdrop-filter: blur(336px); */
   border: 1px solid #5D64FF;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px;
   gap: 50px;
   border-radius: 20px;
}

.support_and_service_top_div_title{
   color: var(--main_font_color);
   font-weight: 700;
   font-size: 32px;
   text-align: center;
}

.service_and_support_img_div{
   width: 70%;
   height: 571px;
}

.service_and_support_img_div img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}


.support_and_service_top_div_info{
   width: 70%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}



.support_and_service_top_div_info >p{
   color: var(--main_font_color);
   font-weight: 500;
}


.support_and_service_top_div_info_div_1{
   display: flex;
   flex-direction: column;
   gap: 20px;
}


.support_and_service_top_div_info_div_1 >h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;

}


.support_and_service_top_div_info_div_1 p{
   color: var(--main_font_color);
   font-weight: 500;
}


.support_and_service_top_div_info_div_2, .support_and_service_top_div_info_div_3{
   display: flex;
   flex-direction: column;
   gap: 50px;
}

.support_and_service_top_div_info_div_2_title, .support_and_service_top_div_info_div_3_title{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;
}


.support_and_service_top_div_info_div_2_title h3, .support_and_service_top_div_info_div_3_title h3{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 24px; 
   text-align: center;
}


.support_and_service_top_div_info_div_2_info, .support_and_service_top_div_info_div_3_info{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 50px;
}

.support_and_service_top_div_info_div_2_info ul, .support_and_service_top_div_info_div_3_info ul{
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: 45%;
}

.support_and_service_top_div_info_div_2_info ul li, .support_and_service_top_div_info_div_3_info ul li{
   display: flex;
   align-items: start;
   gap: 10px;
   color: var(--main_font_color);
   font-weight: 400;
   
}


.support_and_service_page_our_partners .container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 50px;
}

.support_and_service_page_our_partners_title {
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
}


.support_and_service_page_our_partners_title h2{
   color: var(--main_font_color);
   font-weight: 900;
   font-size: 28px;
   text-align: center;
}


.support_and_service_page_our_partners_title p{
   color: var(--main_font_color);
   font-weight: 500;
   text-align: center;
}



@media(max-width: 1024px){
   .service_and_support_img_div{
     width: 90%;
     height: 471px;
   }
  
   .support_and_service_top_div_info{
     width: 90%;
   }

   .support_and_service_top_div_info_div_2_info ul, .support_and_service_top_div_info_div_3_info ul{
      width: 90%;
   }
  }
  



@media(max-width: 768px){
   .service_and_support_img_div{
     width: 100%;
     height: 371px;
   }
  
   .support_and_service_top_div_info{
     width: 100%;
   }

   .services_and_support_top_div{
      padding: 50px 20px;
   }
  }


@media(max-width: 480px){
   .support_and_service_page_our_partners_title p{
      width: 300px;
   }
}