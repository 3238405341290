.change-password-modal{
    display: flex;
    justify-content: center;
    align-items: center;                                                         
    position: fixed;                                                             
    z-index: 999999999999999999999;                         
    left: 0;                                                                                           
    top: 0;                                                                                            
    width: 100%; /* Full width */                                                                      
    height: 100%; /* Full height */                                                                    
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.64);
    padding: 100px 0;
}

.change-password-modal-block{
    width: 40%;
    background-color: #010526;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:  50px;
    animation: modalOrderAnim .5s;
    overflow-y: auto;
    gap: 30px;
}




.change-password-modal-block  h5{
    color: var(--main_font_color);
    font-size: 18px;
    font-style: normal;
    line-height: normal;
}

@media(max-width: 1140px){
    .change-password-modal-block{
        width: 70%;
    }
}

@media(max-width: 720px){
    .change-password-modal-block{
        width: 90%;
    }
}