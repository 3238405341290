
.mosaic-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 100px 0;
}


.mosaic-grid-item {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #5e517e4d;
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.mosaic-grid :nth-of-type(odd) .mosaic-grid-item{
  flex-direction: column-reverse; 
}





.mosaic-grid-item > img {
 width: 100%;
 height: 70%;
}

.mosaic-grid-item-text-div{
  padding: 20px;
}
.mosaic-grid-item-text-div p {
  font-size: 15px;
  width: 100%;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show before truncating with ellipsis */
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  line-height: 20px;
}

.mosaic-grid-item-text-div p::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1.5em; /* Adjust the height as needed for the fade effect */
}

@media(max-width:1024px){
  
  .mosaic-grid-item-text-div p{
    font-size: 11px;
  }
}