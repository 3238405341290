.news-category-page {
    background-color: var(--main_color);
    padding: 50px 0;
    min-height: 100vh;
}


.news-category-page .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 180px;
}

.news-category-page-items-block {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.news-category-page-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 40px;
}

.news-category-page-item {
    width: 300px;
    cursor: pointer;
    height: 350px;
}

.news-category-page-item-img-div{
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.news-category-page-item-img-div img{
    width: 100%;
    height: 100%;
    border-radius: 0;
    transition: all 1s;
}

.news-category-page-item-img-div img:hover{
    transform: scale(1.2);
}

.news-category-page-item-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
}
.news-category-page-item-info p{
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to show before truncating with ellipsis */
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    color: var(--main_font_color);
}

.news-category-page-item-info p::after{
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1.5em;
}

.news-category-item-share-and-date-div{
    display: flex;
    gap: 20px;
    align-items: center;
}

.news-category-item-share-and-date-div >span{
    border-right: 1px solid gray;
    padding-right: 20px;
}

.news-category-item-share-and-date-div span{
    color: white;
}

.pagination_ul{
    display: flex;
    padding-top: 50px;
    gap: 20px;
}

.pagination_ul li{
    padding: 5px 20px;
    color: var(--main_color);
    cursor: pointer;
}


.news-category-page-items-block h3{
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--main_font_color);
}

.news-category-page-items-block h3 svg{
    width: 77%;
    filter: brightness(0) saturate(100%) invert(88%) sepia(4%) saturate(494%) hue-rotate(316deg) brightness(112%) contrast(99%);
    color: white;
}


@media(max-width:1024px){
    .news-category-page .container{
        padding: 0 100px;
    }
}