@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

/* :root {
  --clr: #010526;
}  */

@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

.navigation {
   position: fixed;
   width: 100%;
   height: 70px;
   background: #32358b;
   display: flex;
   justify-content: center;
   align-items: center;
   bottom: 0;
   left: 0;
   display: none;
   z-index: 1;
}

.navigation ul {
   display: flex;
   width: 350px;
   justify-content: space-between;
   margin-block-start: 0;
   margin-block-end: 0;
   padding-inline-start: 0;
}
.navigation ul li {
   position: relative;
   list-style: none;
   width: 70px;
   height: 70px;
   z-index: 1;
}
.navigation ul li a {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: 100%;
   text-align: center;
   font-weight: 500;
}
.navigation ul li a .icon {
   position: relative;
   display: block;
   line-height: 75px;
   font-size: 1.5em;
   text-align: center;
   transition: 0.5s;
   color: var(--clr);
}
.navigation ul li.active a .icon {
   transform: translateY(-32px);
}
.navigation ul li a .text {
   position: absolute;
   color: var(--main_font_color);
   font-weight: 400;
   font-size: 0.75em;
   letter-spacing: 0.05em;
   transition: 0.5s;
   opacity: 0;
   transform: translateY(20px);
}
.navigation ul li.active a .text {
   opacity: 1;
   transform: translateY(10px);
}
.indicator {
   position: absolute;
   top: -50%;
   width: 70px;
   height: 70px;
   background: #010526;
   border-radius: 50%;
   border: 6px solid var(--clr);
   transition: 0.5s;
   /* background-image: url('../../images/'); */
}

/* .elips{
  position: absolute;
  top: -32px;
  left: -8px;
  transform: rotate(2deg);
} */

/* Indicator Transitions */
.navigation ul li:nth-child(1).active ~ .indicator {
   transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
   transform: translateX(calc(70px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
   transform: translateX(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
   transform: translateX(calc(70px * 3));
}

.navigation ul li:nth-child(5).active ~ .indicator {
   transform: translateX(calc(70px * 4));
}

.nav_top_phone {
   position: relative;
}

.navigation .nav_top_phone .tel {
   position: absolute;
   left: -100px;
   top: -110px;
   width: 160px;
   background-color: #1a1a42;
   backdrop-filter: blur(50px);
   transform: rotateX(90deg);
   transition: all 0.3s;
   transform-origin: top center;
   display: flex;
   flex-direction: column;
}

/* .nav_top_phone:hover .tel{
  transform: rotateX(0);
} */

@media (max-width: 480px) {
   .navigation {
      display: flex;
   }
}
