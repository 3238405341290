.nav-menu-div{
    display: flex;
    align-items: center;
    column-gap: 29px;
}


.nav-menu{
    display: flex;
    column-gap: 20px;
    padding: 0 30px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}


/* ======================================== */

.drop-item{
    position: relative;
    display: flex;
    align-items: center;
}


.nav-menu .drop-menu{
    position: absolute;
    top: 30px;
    left: 0;
    background-color:  #050a3abb;
    backdrop-filter: blur(50px);
    transform: rotateX(90deg);
    transition: all .5s;
    transform-origin: top center;
    width: 350px;
    padding-inline-start : 0;
}

.drop-item:hover ul{
    transform: rotateX(0);
    
}
.nav-menu .drop-menu >li{
    padding: 10px 20px;
}

.nav-menu .drop-menu >li a{
    font-size: 13px;
    transition: all 1s;
}
.nav-menu .drop-menu >li:hover{
    background-color: #091156bb;
}

.nav-menu .drop-menu >li:hover a{
    border: none;
    transform: scale(1.2);
}


.programing{
    color: var(--main_font_color);
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    transition: all .4s;
    display: flex;
    align-items: center;
    gap: 10px;
}

.programing:hover{
    border-bottom: 1px solid var(--main_font_color);
}
.programing >svg{
    transition: all .6s;
}
.drop-item:hover .programing >svg{
    transform: rotate(180deg);
}
@media(min-width: 1920px){
    .programing{
        font-size: 20px;
    }
    
  }

@media(max-width: 1920px){
    .programing{
        font-size: 16px;
    }
    
  }

@media(max-width: 1440px){
    .nav-menu{
        padding: 0 2px;
        column-gap: 15px;
    }

    .programing{
        font-size: 13px;
    }
  }


  @media(max-width: 1140px){
    .nav-menu{
        padding: 0 2px;
        column-gap: 13px;
    }

    .programing{
        font-size: 10px;
    }
  }


  @media(max-width: 1024px){
    

    .programing{
        font-size: 8px;
    }
  }
 


  @media(max-width: 780px){
    .nav-menu-div{
        column-gap: 50px !important;
    }
  }
