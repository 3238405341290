.nav-menu-item a{
    color: var(--main_font_color);
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    transition: all .4s;
    padding-bottom: 5px;
}

.nav-menu-item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.nav-menu-item a:hover{
    border-bottom: 1px solid white;
}

.active-item{
    border-bottom: 1px solid white;
    padding-bottom: 5px;
}

@media(min-width: 1920px){
    .nav-menu-item a{
        font-size: 20px;
    }
  }


@media(max-width: 1920px){
    .nav-menu-item a{
        font-size: 16px;
    }
    
  }

@media(max-width: 1440px){
    .nav-menu-item a{
        font-size: 13px;
    }
  }


  @media(max-width: 1140px){
    .nav-menu-item a{
        font-size: 10px;
    }
  }


