.loop-slide {
    overflow: hidden;
}
.loop-slide > div {
    display: flex;
    align-items: center;
    animation: slide-to-left 30s linear infinite;        /* added */
    height: 200px;
    gap: 30px;
}


.loop-slide > div > div {
    min-width: calc(100% / 8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 21px;
    height: 100%;
}



.loop-slide > div > div span{
   /* text-align: center; */
   color: var(--main_font_color);
}

.loop-slide > div:hover{
    animation-play-state: paused;
}

@keyframes slide-to-left {
    100% { transform: translateX(-100%) }
}


