

.mosaic-slide-grid-item{
    width: 80%;
    height: 400px;
    border-radius: 20px;
}

.mosaic-slide-grid-item > img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}


  
.mosaic-grid-item > img {
  width: 100%;
  height: 70%;
 }
 
 .mosaic-grid-item-text-div{
   padding: 20px;
 }
 .mosaic-grid-item-text-div p {
   font-size: 15px;
   width: 100%;
   margin: 0;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* Number of lines to show before truncating with ellipsis */
   -webkit-box-orient: vertical;
   overflow: hidden;
   position: relative;
   line-height: 20px;
 }
 
 .mosaic-grid-item-text-div p::after {
   content: "...";
   position: absolute;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 1.5em; /* Adjust the height as needed for the fade effect */
 }
 
 @media(max-width:1024px){
   
   .mosaic-grid-item-text-div p{
     font-size: 11px;
   }
 }